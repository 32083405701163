<template>
  <div
    class="shipping-containers px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/dotcontainer1-min.jpg"
          width="305"
          height="228"
          alt="DOT Shipping Containers"
          title="DOT Shipping Containers"
          class="img-responsive m-auto"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="DOT-SP Shipping Containers" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) manufactures shipping
            containers for transportation under DOT Special Permit 8451 (DOT-SP
            8451) and DOT Special Permit 13481 (DOT-SP 13481).
          </p>
          <p>
            Items can be purchased by calling 801-567-0456 or at our
            <router-link to="/shop">online store</router-link>.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            DOT-SP 8451 Shipping Containers
          </h2>
          <p>
            DOT-SP 8451 was previously identified as DOT Exemption 8451 (DOT-E
            8451). The DOT-SP 8451 special permit authorizes transportation of
            no more than 25 grams of explosive or pyrotechnic material (with an
            energy density not significantly greater than PETN) as Division 1.4E
            when packed in a special shipping container. SMS refers to them as
            “shipping pipe assemblies.”
          </p>
          <p>
            Allowable shipping containers include 4-inch x 14-inch and 6-inch x
            12- or 14-inch Schedule 80 seamless steel pipe nipples closed at
            both ends with appropriately-rated end caps. The pipe threads must
            be cut so that the caps can be hand screwed to a minimum of 5
            threads.
          </p>
          <p>
            In addition, this special permit states that these pipe containers
            must be contained within a cushioned UN 4G fiberboard box, 4C
            aluminum box, 4N metal (other than steel or aluminum) box, 1A2
            removable head steel drum, 1B2 removal head aluminum drum, or 1N2
            removable head metal (other than steel or aluminum) drum.
          </p>
          <p>
            SMS currently offers 4-inch x 14-inch and 6-inch x 14-inch seamless
            steel pipe containers with 3,000-pound end caps. The shipping
            containers may be purchased with a UN 4D plywood box as the
            containment package, which has been certified to UN Non-bulk
            Performance-oriented Packaging (POP) Standards. The containment
            package is equipped with retracting foam-padded handles and foam
            supports to support the pipe, as shown in the figure below.
            Containment packages and shipping container can be purchased as a
            set or individually.
          </p>
          <p>
            These shipping pipe assemblies can also be used as a helpful
            <router-link to="/risk-management/facility-siting-and-design"
              >facility siting</router-link
            >
            and storage solution for businesses using or producing small amounts
            of explosive material. When properly packed, one of more of the
            shipping pipes can be used to store or transport up to 25 grams each
            of material without regards to quantity distance. This is especially
            useful for small operations where quantity distance is difficult to
            maintain due to proximity to neighboring businesses or other exposed
            sites nearby. Once the material is removed from the shipping pipe
            assembly for operations, it is again subject to normal quantity
            distance regulations, however, choosing when and where this material
            is removed from the assembly allows for greater freedom to operate
            and will often allow for greater quantities than would otherwise be
            possible at a particular site.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            DOT-SP 13481 Shipping Containers
          </h2>
          <p>
            DOT-SP 13481 was previously identified as DOT Exemption 13481 (DOT-E
            13481). The DOT-SP 13481 special permit authorizes transportation of
            no more than 25 grams of liquid explosive substances (with an energy
            density not greater than pure nitroglycerin or NG) as Division 1.4E
            when also packed in a special shipping container.
          </p>
          <p>
            Allowable shipping containers include 4-inch x 15-inch (and larger)
            flanged Schedule 80 seamless pipe section capped on both ends with
            8-bolt 150-pound flange plates which are sealed with
            high-temperature resistant graphite gaskets. SMS requires the inside
            welded joints on the DOT-SP 13481 shipping pipes to be continuous
            with 100% fusion. SMS also requires that the inside welded joints
            are liquid penetrant, ultrasound, and mag particle inspected to
            ensure a liquid-tight seal since, in the event of a leak, spill or
            splash, the welds would likely be contaminated with liquid
            explosives.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "DOT-SP Shipping Containers",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufactures shipping containers for transportation under DOT Special Permit 8451 (DOT-SP 8451) and DOT Special Permit 13481 (DOT-SP 13481)."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.shipping-containers {
  a {
    color: #aa222c;
  }
  h2 {
    color: black;
    font-size: 18px;
    margin-bottom: 1.1em;
  }
}
</style>
